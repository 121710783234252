import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import Cart from './components/Cart/Cart';
import CartButton from './components/CartButton/CartButton';
import Header from './layout/Header/Header';
import Footer from './layout/Footer/Footer';
import Menu from './components/Menu/Menu';

import configureStore from './redux/configureStore';

// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';


import './styles/style.scss';

// Sentry.init({
//   dsn: 'https://d0db4de15b60462695ba5718b9429703@sentry.devservice.1cbit.ru/11',
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0, // Отправлять все трейсы (0.0 - ничего, 1.0 - все)
// });


const store = configureStore();

export class App extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      active: false
    };
    this.setModalState = this.setModalState.bind(this);
  }

  setModalState (force) {
    const { active } = this.state;
    if(typeof(force) == "boolean"){
      this.setState({ active: force });
    }else{
      this.setState({ active: !active });
    }
  }
  

  render () {
    return (
      <Provider store={store}>
        <div className="App">
          <Header />
          <Menu setActive={this.setModalState} />
          <Cart active={this.state.active} setActive={this.setModalState} />
          <CartButton onClick={() => this.setModalState()} />
          <Toaster />
          <Footer />
        </div>
      </Provider>
    );
  }
}

export default App;
