import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
// import axios from 'axios';
// import { apiUrls } from '../../config';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import * as yup from 'yup';
// import CustomSelect from '../.././components/CastomSelect/CastomSelect'
// import { maxOrderCost,/* pb_key */ } from "../../config";
import {
  formatter, getDefaultPoint, loadUserCookie, phoneRegExp, /*saveDeliveryCookie*/
} from '../../helpers/utils';
import {
  clearCart, clearSavedInOtherDateCart, moveItemToInOtherDate, removeItem, sendOrder
} from '../../redux/modules/cart';

import CartItem from './CartItem/CartItem';

// import * as Sentry from '@sentry/react';


import styles from './Cart.module.scss';
import Closed from '../../assets/images/close.png';
// import DatesFilter from '../Menu/DatesFilter/DatesFilter';
import PublicOferta from '../../assets/documents/PublicOferta.pdf'
import PoliticConfidentiality from '../../assets/documents/PoliticConfidentiality.pdf'

const initFormValue = {
  ...loadUserCookie(),
  point_id: getDefaultPoint().value,
  sel_index: getDefaultPoint().index,
  is_payment: false,
};

const Cart = ({ active, setActive }) => {
  const dispatch = useDispatch();
  const {
    items, cartCount, /*pointsList*/
  } = useSelector(state => state.cart);
  const { items: menu, activeDate } = useSelector(state => state.menu);
  const [orderSent, setOrderSent] = useState(false);
  const [orderSentInfo, setOrderSentInfo] = useState();
  // const [isPaymentOnline, setIsPaymentOnline] = useState(false);

  useEffect(() => {
    if (typeof items !== 'object') {
      dispatch(clearCart());
      dispatch(clearSavedInOtherDateCart());
    }
  }, []);

  // useEffect(() => {
  //   dispatch(removeUnactive(activeDate));
  // }, [activeDate])


  if (!menu.length) { return <></>; }
  const calculateCartTotal = () => {
    let localTotal = 0;
    items.forEach(cartItem => {
      if (cartItem.date == activeDate && menu.find(item => item.good_id == cartItem.menu_item)) {
        localTotal += menu.find(item => item.good_id == cartItem.menu_item).good_price * cartItem.quantity;
      }
    })
    return localTotal;
  };

  // const calculateCartTotalWithDiscount = () => {
  //   const cartTotal = calculateCartTotal();
  //   const discountAmount = cartTotal * (percent / 100);
  //   const totalWithDiscount = cartTotal - discountAmount;
  //   return totalWithDiscount;
  // };
  
  const validationSchema = yup.object().shape({
    name: yup.string().required('Введите имя'),
    email: yup.string().email('Введите корретный email').required('Введите email'),
    phone: yup.string().matches(phoneRegExp, 'Введите корретный телефон').required('Введите телефон'),
    inn: yup.string().min('10', "ИНН должен состоять минимум из 10 цифр").required('Введите ИНН'),
    company_name: yup.string().required('Введите название компании'),
  });

  const removeCartItem = (goodId, price) => {
    dispatch(removeItem(goodId, price));
  };

  const moveToOtherDate = (goodId, price) => {
    dispatch(moveItemToInOtherDate(goodId, price));
  };

  // const handlePointChange = ({ value, index }, handleChange) => {
  //   saveDeliveryCookie({ value, index });

  //   const event = {
  //     persist: () => { },
  //     target: {
  //       type: 'change',
  //       value,
  //       name: 'point_id'
  //     }
  //   };
  //   handleChange(event);
  // };

  const showErrorNotification = () => toast.error('Некоторые товары из корзины не доступны для заказа в выбранную дату', {
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff'
    }
  });

  // const showErrorPay = () => toast.error('Ваш заказ успешно создан!К сожалению, прямо сейчас предоплата невозможна из-за технической ошибки.Пожалуйста, оплатите заказ на месте, когда будете его забирать.Приносим извинения за неудобство.', {
  //   duration: 6000,
  //   style: {
  //     borderRadius: '10px',
  //     background: '#333',
  //     color: '#fff'
  //   }
  // });

  // function pay(order) {
  //   const widget = new cp.CloudPayments();
  //   widget.pay('charge', // или 'auth'
  //     { //options
  //       publicId: pb_key, //id из личного кабинета
  //       description: 'Оплата товаров в order.reatme.ru', //назначение
  //       amount: calculateCartTotalWithDiscount() / 100, //сумма
  //       currency: 'RUB', //валюта
  //       autoClose: 3, //время в секундах до авто-закрытия виджета (необязательный)
  //       invoiceId: order.id, //номер заказа  (необязательно)
  //       email: order.email, //email плательщика (необязательно)
  //     },
  //   ).then(async (result) => {
  //       // await axios.post(apiUrls.pay, {
  //       //   id: order.id,
  //       //   guid: order.guid,
  //       //   transaction_id: result.data.transactionId
  //       // }, getRequestConfig());
  //       // setOrderSent(true);
  //       Sentry.captureException(result);
  //       hundleCartRemove();
  //   }).catch((error) => {
  //     // Обработка ошибки при вызове метода widget.pay
  //     Sentry.captureException(error);
  //     showErrorPay();
  //     hundleCartRemove();
  //   });
  // }

  // const handlePay = (handleChange) => {
  //   const event = {
  //     persist: () => { },
  //     target: {
  //       type: 'change',
  //       value: true,
  //       name: 'is_payment'
  //     }
  //   };
  //   handleChange(event);
  //   setIsPaymentOnline(true);
  // };

  const hundleCartRemove = () => {
    setOrderSent(true);
    dispatch(clearCart());
    setTimeout(() => {
      if (active == true) {
        setActive(false);
        setOrderSent(false);
        setOrderSentInfo(undefined);
      }
    }, 10000);
  }

  // const selectOptions = pointsList.map((point, index) => ({
  //   value: point.id,
  //   label: point.address,
  //   name: point.name,
  //   index
  // }));

  const errorNotify = e => toast.error(e, {
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff'
    }
  });

  const formSubmit = async (values) => {
    try {
      // if (Number(calculateCartTotal() / 100)/* > maxOrderCost*/) {
      //   errorNotify(`Максимальная сумма заказа - ${formatter.format(maxOrderCost)}`);
      //   return;
      // }
      // if (items.filter(item => item.date != activeDate).length > 0) {
      //   errorNotify(`Дата доставки и меню изменились, Вам необходимо выбрать товары заново`);
      //   return;
      // }

      const result = await dispatch(sendOrder({ ...values }));
      values.items = items;
      setOrderSentInfo(values);
      if (result) {
        if (values.is_payment) {
          // pay({ ...result.data, ...values });
        } else {
          hundleCartRemove()
        }
      } else {
        throw new Error('Ошибка отправки заказа');
      }
    } catch (e) {
      errorNotify(e.message);
    }
  };

  const renderOrderSent = () => (<>
    {orderSent && <div className={styles.orderSent}>
      <h1>Спасибо! Ваш онлайн-заказ отправлен.</h1>
      <hr />
      <p>Список товаров</p>
      <ul>
        {orderSentInfo.items.map((item, index) => (
          <li key={index}>{index + 1}. {menu[item.index].good_name}</li>
        ))}
      </ul>
    </div>}
  </>);

  return (
    <div className={cn(styles.cart_modal, { [styles.active]: !active })}>
      <button className={styles.btn_modal} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setActive(); }}>
        <img src={Closed} alt="exit" />
      </button>
      <div className={styles.wrapper}>
        {renderOrderSent()}
        {/* <h1>Даты для доставки <DatesFilter /></h1> */}
        <h1>Ваш заказ:</h1>
        <hr />
        <ul className={styles.list}>
          {items.map(cartItem => (
            <li key={`cartitem-${cartItem.menu_item}`} className={styles.card}>
              <CartItem
                cartItem={cartItem}
                menuItem={menu[cartItem.index]}
                removeCartItem={removeCartItem}
                inOtherDate={activeDate !== cartItem.date}
                moveToOtherDate={moveToOtherDate}
                showErrorNotification={showErrorNotification}
              />
            </li>
          ))}
        </ul>
        <span>
          {`Итого: ${formatter.format(calculateCartTotal() / 100)}`}
        </span>
        <hr />
        <Formik
          initialValues={initFormValue}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            formSubmit(values, resetForm);
          }}
        >
          {({
            values, touched, errors, handleChange
          }) => (
            <Form noValidate autoComplete="off">
              <input
                className={cn({ [styles.invalid]: errors.name && touched.name })}
                type="text"
                name="name"
                placeholder="Ваше имя"
                value={values.name}
                onChange={handleChange}
              />
              <label htmlFor="name">{errors.name}</label>
              <input
                className={cn({ [styles.invalid]: errors.email && touched.email })}
                type="email"
                name="email"
                placeholder="Ваш email"
                value={values.email}
                onChange={handleChange}
              />
              <label htmlFor="email">{errors.email}</label>
              <input
                className={cn({ [styles.invalid]: errors.phone && touched.phone })}
                type="tel"
                name="phone"
                placeholder="+7 (999) 999-9999"
                pattern="[0-9]*"
                value={values.phone}
                onChange={handleChange}
              />
              <label htmlFor="phone">{errors.phone}</label>
              <input
                className={cn({ [styles.invalid]: errors.inn && touched.inn })}
                type="inn"
                name="inn"
                placeholder="Ваш ИНН"
                value={values.inn}
                onChange={handleChange}
              />
              <label htmlFor="inn">{errors.inn}</label>
              <input
                className={cn({ [styles.invalid]: errors.company_name && touched.company_name })}
                type="company_name"
                name="company_name"
                placeholder="Название компании"
                value={values.company_name}
                onChange={handleChange}
              />
              <label htmlFor="company_name">{errors.company_name}</label>
              <p>Нажимая на кнопку "Заказать", вы соглашаетесь с <a href={PoliticConfidentiality} target="_blank">Политикой конфиденциальности</a> и <a href={PublicOferta} target="_blank">условиями оферты</a>.</p>
              <button disabled={cartCount === 0} type="submit">Заказать</button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Cart;
