import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { formatter } from '../../../helpers/utils';
import { addItem, changeItemCount } from '../../../redux/modules/cart';
import NewBadge from "../../NewBadge/NewBadge";
import MenuItemCounter from './MenuItemCounter/MenuItemCounter';

import styles from '../MenuItem/MenuItem.module.scss'

const MenuItem = (props) => {
  const dispatch = useDispatch();
  const [inCartIndex, setInCartIndex] = useState(-1);
  const [quantity, setQuantity] = useState(1);
  const [showComposition, setShowComposition] = useState(false);
  const [showCalories, setShowCalories] = useState(false);

  const {
    item: {
      index, img, good_id, good_name, composition, calories, goods_weight, good_price, sku, is_new
    }, cart, inCartIndexList, item
  } = props;
  const { setActive } = props;


  useEffect(() => {
    if (inCartIndex >= 0) {
      dispatch(changeItemCount({
        menu_item: good_id, counter: quantity, price: good_price
      }));
    }
  }, [quantity]);

  useEffect(() => {
    const inCartIndex = inCartIndexList.indexOf(sku);

    if (inCartIndex >= 0) {
      setInCartIndex(inCartIndex);

      if (quantity !== cart[inCartIndex].quantity) setQuantity(cart[inCartIndex].quantity);
    } else {
      setInCartIndex(-1);
      setQuantity(1);
    }
  }, [props]);

  const addToCart = (inCartIndex) => {
    if (inCartIndex >= 0) {
      setActive();
    } else {
      dispatch(addItem({
        index, menu_item: good_id, quantity, price: good_price, sku
      }));
      setQuantity(1);
    }
  };

  return (
    <li key={good_id} className="card">
      {is_new && <NewBadge />}
      <img src={img} alt="" />
      <div className="card-body">
        <h2>{good_name}</h2>
        {/* <h3>{goods_weight.split('.').slice(1).join('').concat('0') + ` г`}</h3> */}
        <div className={cn(styles.composition, { ["active"]: showComposition })}>
          <button onClick={() => setShowComposition(!showComposition)}>{showComposition ? "Скрыть состав" : "Состав"}</button>
          {composition && showComposition && <p>{composition}</p>}
          <button onClick={() => setShowCalories(!showCalories)}>{showCalories ? "Скрыть калорийность" : "Калорийность"}</button>
          {calories && showCalories && <p>{calories}</p>}
        </div>
      </div>
      <div className="card-footer">
        <span className="cart-item-price">
          {formatter.format(good_price / 100)}
        </span>
        <MenuItemCounter menuItem={item} quantity={quantity} setQuantity={setQuantity} />
        <button className={cn({ inCart: inCartIndex >= 0 })} onClick={() => addToCart(inCartIndex)}>{inCartIndex >= 0 ? 'В корзине' : 'Заказать'}</button>
      </div>
    </li>
  );
};

export default MenuItem;

