import axios from 'axios';
import { apiUrls } from '../../config';
import { getRequestConfig } from '../../helpers/apiHelper';
import { moveItemFromInOtherDate } from './cart';

const LOAD_MENU = 'shop/menu/LOAD_MENU';
// const LOAD_DATES = 'shop/menu/LOAD_DATES';
// const SET_DATE_FILTER = 'shop/menu/SET_DATE_FILTER';
const SET_CATEGORY_FILTER = 'shop/menu/SET_CATEGORY_FILTER';
const SHOW_MORE = 'shop/menu/SHOW_MORE';

const initialState = {
  items: [],
  // dates: [],
  categories: [],
  // activeDate: new Date(),
  activeFilterIndex: 0,
  allItemsLimit: 6
};

// Actions
// export const loadDates = () => async (dispatch) => {
//   const { data: dates } = await axios.get(apiUrls.loadDates, getRequestConfig());

//   dispatch({ type: LOAD_DATES, dates });

//   return dates;
// };

export const loadMenu = () => async (dispatch) => {
  try {
    const { data: menu }  = await axios.get(`${apiUrls.loadMenu}`, getRequestConfig());

    if (menu?.categories && menu.categories.length > 0) {
      dispatch(setActiveFilter(menu.categories[0].id));
    }

    dispatch(moveItemFromInOtherDate(menu));
    dispatch({ type: LOAD_MENU, menu });
  } catch (e) {
    console.log(e);
  }
};

export const setActiveFilter = id => async (dispatch) => {
  dispatch({ type: SET_CATEGORY_FILTER, activeFilterIndex: id });
};

// export const setActiveDateFilter = date => async (dispatch) => {
//   dispatch({ type: SET_DATE_FILTER, activeDate: date });
// };

export const showMore = () => async (dispatch, getState) => {
  const { allItemsLimit,items } = getState().menu;
  if(allItemsLimit < items.length){
    dispatch({ type: SHOW_MORE, allItemsLimit: allItemsLimit + 6 });
  }
  return
};

// Reducers
export default function reducer (state = initialState, action) {
  switch (action.type) {
    case LOAD_MENU:
      return { ...state, ...action.menu };
    // case LOAD_DATES:
    //   return { ...state, dates: action.dates };
    case SET_CATEGORY_FILTER:
      return { ...state, activeFilterIndex: action.activeFilterIndex };
    // case SET_DATE_FILTER:
    //   return { ...state, activeDate: action.activeDate };
    case SHOW_MORE:
      return { ...state, allItemsLimit: action.allItemsLimit };
    default:
      return state;
  }
}
