import axios from 'axios';
import { apiUrls } from '../../config';
import { getRequestConfig } from '../../helpers/apiHelper';
import {
  loadCartCookie, loadCartCount, loadCartIndexList, loadCartTotal, removeCartCookie, saveCartCookie, saveUserCookie
} from '../../helpers/utils';

const ADD = 'shop/cart/ADD';
const INC_COUNTER = 'shop/cart/INC_COUNTER';
const DEC_COUNTER = 'shop/cart/DEC_COUNTER';
const CHANGE_ITEM_COUNT = 'shop/cart/CHANGE_ITEM_COUNT';
const CHANGE_CART_TOTAL = 'shop/cart/CHANGE_CART_TOTAL';
const REMOVE = 'shop/cart/REMOVE';
const CLEAR = 'shop/cart/CLEAR';
const CLEAR_SAVED = 'shop/clear/CLEAR_SAVED';
const POINTS = 'shop/cart/POINTS';

const initialState = {
  items: loadCartCookie(),
  inOtherDateItems: [],
  cartCount: loadCartCount(),
  total: loadCartTotal(),
  pointsList: [],
  inCartIndexList: loadCartIndexList()
};

// Action
export const addItem = ({
  index, menu_item, quantity, price, sku
}) => async (dispatch, getState) => {
  const {
    items, total, cartCount, inCartIndexList
  } = getState().cart;
  // const { activeDate } = getState().menu;
  let itemCount = 0;
  let cartTotal = total;
  let foundInCart = false;

  if (items) {
    items.forEach((item) => {
      if (item.menu_item === menu_item) {
        foundInCart = true;
        // eslint-disable-next-line no-param-reassign
        item.quantity += quantity;
        itemCount += quantity;
        cartTotal += price * quantity;
      }
    });

    if (!foundInCart) {
      inCartIndexList.push(sku);
      items.push({
        index, menu_item, quantity, /*date: activeDate,*/ sku
      });
    }

    itemCount = foundInCart ? itemCount : itemCount + quantity;
    cartTotal = foundInCart ? cartTotal : cartTotal + (price * quantity);
  } else {
    cartTotal = (price * quantity);
    itemCount = quantity;
    inCartIndexList.push(sku);
    items.push({
      index, menu_item, quantity, /*date: activeDate,*/ sku
    });
  }

  dispatch({ type: ADD, payload: items });
  dispatch({ type: INC_COUNTER, itemCount: cartCount + itemCount });
  dispatch({ type: CHANGE_CART_TOTAL, cartTotal });

  saveCartCookie(items, cartCount + itemCount, cartTotal, inCartIndexList);
};

export const removeItem = (menu_item, price) => async (dispatch, getState) => {
  const {
    items, cartCount, total, inCartIndexList
  } = getState().cart;
  const cartItemIndex = items.findIndex(item => item.menu_item === menu_item);
  const inCartListIndex = inCartIndexList.indexOf(items[cartItemIndex].sku);
  const itemCount = cartCount - items[cartItemIndex].quantity;
  const cartTotal = total - items[cartItemIndex].quantity * price;
  items.splice(cartItemIndex, 1);
  inCartIndexList.splice(inCartListIndex, 1);

  if (items.length === 0) {
    dispatch(clearCart());
  } else {
    dispatch({ type: DEC_COUNTER, itemCount });
    dispatch({ type: CHANGE_CART_TOTAL, cartTotal });
    dispatch({ type: REMOVE });

    saveCartCookie(items, itemCount);
  }
};

export const moveItemToInOtherDate = (menu_item, price) => async (dispatch, getState) => {
  const { inOtherDateItems } = getState().cart;
  const {
    items
  } = getState().cart;
  const cartItem = items.find(item => item.menu_item === menu_item);

  if (cartItem) {
    dispatch(removeItem(menu_item, price));
  }

  inOtherDateItems.push(cartItem);
};

export const moveItemFromInOtherDate = (/*activeDate*/ menu) => async (dispatch, getState) => {
  const { inOtherDateItems } = getState().cart;

  for (let i = inOtherDateItems.length - 1; i >= 0; i--) {
    const menuItemIndex = menu.items.findIndex(item => item.sku === inOtherDateItems[i].sku);
    dispatch(addItem({ ...inOtherDateItems[i], price: menu.items[menuItemIndex].good_price, index: menuItemIndex }));
    inOtherDateItems.splice(i, 1);
  }
};

export const changeItemCount = ({
  menu_item, counter, price
}) => async (dispatch, getState) => {
  const {
    items, cartCount, total, inCartIndexList
  } = getState().cart;
  let itemsCount = cartCount;
  let cartTotal = total;

  if (items) {
    for (const item of items) {
      if (item.menu_item === menu_item) {
        // eslint-disable-next-line no-param-reassign,no-unused-vars
        itemsCount -= (item.quantity - counter);
        cartTotal -= (item.quantity - counter) * price;
        item.quantity = counter;
      }
    }
  }

  dispatch({ type: CHANGE_CART_TOTAL, cartTotal });
  dispatch({ type: CHANGE_ITEM_COUNT, itemsCount });
  saveCartCookie(items, itemsCount, cartTotal, inCartIndexList);
};

export const clearCart = () => async (dispatch) => {
  removeCartCookie();
  dispatch({ type: CLEAR });
};

export const clearSavedInOtherDateCart = () => async (dispatch) => {
  dispatch({ type: CLEAR_SAVED });
};

// export const loadPoints = () => async (dispatch) => {
//   const { data: pointsList } = await axios.get(apiUrls.points, getRequestConfig());

//   dispatch({ type: POINTS, pointsList });
// };

export const sendOrder = formValues => async (dispatch, getState) => {
  try {
    const { items } = getState().cart;
    const { activeDate } = getState().menu;
    const {
      phone, email, name, point_id, inn, company_name
    } = formValues;

    const resultCart = {
      user: {
        name,
        phone: parseInt(phone),
        email,
        inn,
        company_name
      },
      items,
      order_stamp: activeDate,
      point_id
    };

    const result = await axios.post(apiUrls.order, resultCart, getRequestConfig());
    dispatch(clearSavedInOtherDateCart());
    saveUserCookie({ name, phone, email });
    return (result);
  } catch (e) {
    console.log(e);
    throw new Error(e.response.data.detail);
  }
};

// Reducers
export default function reducer (state = initialState, action) {
  switch (action.type) {
    case ADD:
      return { ...state, items: action.payload };
    case INC_COUNTER:
      return { ...state, cartCount: action.itemCount };
    case DEC_COUNTER:
      return { ...state, cartCount: action.itemCount };
    case CHANGE_ITEM_COUNT:
      return { ...state, cartCount: action.itemsCount };
    case CHANGE_CART_TOTAL:
      return { ...state, total: action.cartTotal };
    case REMOVE:
      return { ...state };
    case CLEAR:
      return {
        ...state, items: [], cartCount: 0, total: 0, inCartIndexList: []
      };
    case CLEAR_SAVED:
      return {
        ...state, inOtherDateItems: []
      };
    case POINTS:
      return { ...state, pointsList: action.pointsList };
    default:
      return state;
  }
}
