import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { loadPoints } from '../../redux/modules/cart';
import {
  /*loadDates*/ loadMenu, /*setActiveDateFilter*/ showMore
} from '../../redux/modules/menu';
import CategoriesFilter from './CategoriesFilter/CategoriesFilter';
// import DatesFilter from './DatesFilter/DatesFilter';
import MenuItem from './MenuItem/MenuItem';

const Menu = ({ setActive }) => {
  const menuTarget = useRef(null);
  const dispatch = useDispatch();
  const { inCartIndexList, items: cart } = useSelector(state => state.cart);
  const {
    items, activeFilterIndex, allItemsLimit, /*dates*/
  } = useSelector(state => state.menu);
  const [loadTimer,setLoadTimer] = useState();
  // useEffect(() => {
  //   dispatch(loadDates({}));
  //   dispatch(loadPoints());
  // }, []);

  useEffect(() => {
      // dispatch(setActiveDateFilter(dates[0]));
      dispatch(loadMenu());
  }, []);

  const showMoreItems = () => {
    if(!loadTimer){
      setLoadTimer(setTimeout(()=>{
        dispatch(showMore());
      },1000))
    }else{
      setLoadTimer(null)
    }
  };

  const scrollToMenu = (event) => {
    event.preventDefault();
    menuTarget.current.scrollIntoView({
      behavior: 'smooth'
    });
  };
  document.body.addEventListener('scroll',()=>{
    if(document.querySelector('main').offsetHeight - document.querySelector('.card').offsetHeight - 200 <= document.body.scrollTop+450){
      showMoreItems()
    }
  })
  return (
    <main className="main">
      <section className="section-shop">
        <div className="container">
          <h1>
            Магазин
            {' '}
            <i>R</i>
            eat
            <b>m</b>
            e
          </h1>
          <p>
            Вы можете заказать блюда на любой вкус,
            <br />
            а мы их доставим
          </p>
          <a onClick={scrollToMenu} href="#">Заказать</a>
        </div>
      </section>
      <section ref={menuTarget} className="section-product">
        <div className="container">
          {/* <h1>Меню<span>Заказы на следующий рабочий день принимаются в предшествующий рабочий день до 13-00</span></h1> */}
          {/* <DatesFilter /> */}
          <CategoriesFilter />
          <ul className="list">
            {
              (activeFilterIndex !==0)
                ? items.map((item, index) => (
                  (item.category_id === activeFilterIndex) && <MenuItem key={`${item.good_id}-${index}`} cart={cart} item={{ index, ...item }} inCartIndexList={inCartIndexList} setActive={setActive} />
                ))
                : items.map((item, index) => (
                  (index < allItemsLimit && item.category_id !== 99999999) && <MenuItem key={`${item.good_id}-${index}`} item={{ index, ...item }} cart={cart} inCartIndexList={inCartIndexList} setActive={setActive} />
                ))
            }
          </ul>
          {(activeFilterIndex === 0 && allItemsLimit <= items.length) && <button onClick={showMoreItems} className="button">Показать еще</button>}
        </div>
      </section>
    </main>
  );
};

export default Menu;
