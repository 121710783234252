import Cookies from 'universal-cookie';
import endOfDay from 'date-fns/endOfDay';

const phoneRegExp = /(\+7|8)[- _]*\(?[- _]*(\d{3}[- _]*\)?([- _]*\d){7}|\d\d[- _]*\d\d[- _]*\)?([- _]*\d){6})/g;

const getDefaultPoint = () => {
  const cookies = new Cookies();
  const point = cookies.get('delivery_point');

  if (point) return point;
  return { value: 0, index: 0 };
};

const saveDeliveryCookie = (point_id) => {
  const cookies = new Cookies();
  cookies.set('delivery_point', point_id);
};

const saveCartCookie = (cartItems, cartCount, cartTotal, inCartIndexList) => {
  const expires = (endOfDay(new Date()));

  const cookies = new Cookies();
  cookies.set('cart_total', cartTotal, { path: '/', expires });
  cookies.set('cart_count', cartCount, { path: '/', expires });
  cookies.set('cart_in_cart_index_list', inCartIndexList, { path: '/', expires });
  cookies.set('cart', cartItems, { path: '/', expires });
};

const saveUserCookie = (user) => {
  const cookies = new Cookies();
  cookies.set('cart_user', user);
};

const loadUserCookie = () => {
  const cookies = new Cookies();
  const user = cookies.get('cart_user');

  if (user) return user;
  return { name: '', email: '', phone: '', inn: '', company_name: ''};
};

const loadCartCookie = () => {
  const cookies = new Cookies();
  const cart = cookies.get('cart');

  if (cart) return cart;
  return [];
};

const loadCartCount = () => {
  const cookies = new Cookies();
  const cartCount = cookies.get('cart_count');

  if (cartCount) return Number(cartCount);
  return 0;
};
const loadCartTotal = () => {
  const cookies = new Cookies();
  const cartTotal = cookies.get('cart_total');

  if (cartTotal) return Number(cartTotal);
  return 0;
};

const loadCartIndexList = () => {
  const cookies = new Cookies();
  const inCartIndexList = cookies.get('cart_in_cart_index_list');

  if (inCartIndexList) return inCartIndexList;
  return [];
};

const removeCartCookie = () => {
  const cookies = new Cookies();
  cookies.remove('cart_total');
  cookies.remove('cart_count');
  cookies.remove('cart_in_cart_index_list');
  cookies.remove('cart');
};

const formatter = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'RUB',
  minimumFractionDigits: 0
});

const isHasProductInCart = (items, menuItem) => !!items.find(product => product.menu_item === menuItem.good_id);

export {
  saveCartCookie,
  saveUserCookie,
  saveDeliveryCookie,
  loadUserCookie,
  loadCartCookie,
  loadCartCount,
  loadCartTotal,
  loadCartIndexList,
  removeCartCookie,
  getDefaultPoint,
  formatter,
  phoneRegExp,
  isHasProductInCart,
};
