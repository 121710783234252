import { requestToken } from '../config';

const getRequestConfig = () => {
  const config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    }
  };

  config.headers.Authorization = requestToken;

  return config;
};

export {
  getRequestConfig
};
