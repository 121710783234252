const requestToken = process.env.REACT_APP_USER_TOKEN;
const urlPrefix = process.env.REACT_APP_BACKEND_URL;
const pb_key = process.env.REACT_APP_CLOUDPAYMENTS_PUBLIC_ID;

const apiUrls = {
  loadMenu: urlPrefix+'/menu',
  order: urlPrefix+'/order',
};

export {
  requestToken,
  urlPrefix,
  apiUrls,
  pb_key,
};